exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-mig-mdx": () => import("./../../../src/pages/om-mig.mdx" /* webpackChunkName: "component---src-pages-om-mig-mdx" */),
  "component---src-pages-projekter-js": () => import("./../../../src/pages/projekter.js" /* webpackChunkName: "component---src-pages-projekter-js" */),
  "component---src-templates-project-jsx-content-file-path-src-projects-aifeedback-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/projects/aifeedback.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-projects-aifeedback-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-projects-uniradioen-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/projects/uniradioen.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-projects-uniradioen-mdx" */)
}

